@font-face {
    font-family: 'Metropolis';
    src: url('../assets/fonts/Metropolis-ExtraLight.woff2') format('woff2'),
        url('../assets/fonts/Metropolis-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../assets/fonts/Metropolis-Bold.woff2') format('woff2'),
        url('../assets/fonts/Metropolis-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../assets/fonts/Metropolis-Regular.woff2') format('woff2'),
        url('../assets/fonts/Metropolis-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../assets/fonts/Metropolis-Medium.woff2') format('woff2'),
        url('../assets/fonts/Metropolis-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mansory';
    src: url('../assets/fonts/Mansory-Medium.woff2') format('woff2'),
        url('../assets/fonts/Mansory-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}